import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, EventEmitter, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AUTH_CONFIG, AuthModule, IAuthConfig } from '@sst-inc/angular-auth';
import { ISocketConfig, SOCKET_CONFIG, SocketModule } from '@sst-inc/angular-socket';
import { HotkeyModule } from 'angular2-hotkeys';
import Debug from 'debug';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SentryErrorHandler } from './sentry';
import { DelayInterceptor } from './shared/delayInterceptor';
import { Browser } from './supported-browsers/browserInfo';
import { BrowserGuard } from './supported-browsers/guard.service';
import { SupportedBrowsersComponent } from './supported-browsers/supported-browsers.component';
import './theme';
import { TOLGEE_INSTANCE, Tolgee, BackendFetch, FormatSimple, LanguageDetector, LanguageStorage } from '@tolgee/ngx';
import { availableLanguages } from './availableLanguages';

const debug = Debug('sst:app.module');

// test for electron environment
// need to update environment before use of the provider useValue below
if (window['require']) {
  environment.build.electronWrapperVersion = new URLSearchParams(window.location.search).get('electronWrapperVersion');
  // override the platform
  environment.build.platform = 'electron';
}
const receivedCustomURL = new EventEmitter<string>();

export function getReceivedCustomURL() {
  return receivedCustomURL;
}

// Handler for custom URL scheme (com.shotspotter.alerts://)
(window as any).handleOpenURL = (url) => {
  receivedCustomURL.emit(url);
};

export function closeBrowserTab() {
  debug('should close sso browser tab');
  // defer until we implement cordova.
  // SafariViewController.hide();
}

const authConfig = Object.assign({}, {
  build: environment.build,
  appName: environment.appName,
  oauth2: environment.oauth2,
  getReceivedCustomURL: () => {
    return receivedCustomURL;
  },
  closeBrowserTab,
  urlWhitelist: environment.authUrlWhitelist,
  useRefreshToken: true,
  persistentRefreshToken: true,
  userMeEndpoint: environment.baseUrl.userService + '/v1/me',
}) as unknown as IAuthConfig;

const socketConfig = {
  build: environment.build,
  host: environment.baseUrl.socketService.host,
  path: environment.baseUrl.socketService.path,
  appName: environment.appName,
} as ISocketConfig;

const providers: any[] = [
  { provide: AUTH_CONFIG, useValue: authConfig },
  { provide: SOCKET_CONFIG, useValue: socketConfig },
  { provide: ErrorHandler, useClass: SentryErrorHandler },
  {
    provide: TOLGEE_INSTANCE,
    useFactory: () => {
      return Tolgee()
        .use(BackendFetch({
          prefix: environment.baseUrl.userService + '/v1/i18n',
          headers: {
            'X-App-Name': environment.appName
          }
        }))
        .use(FormatSimple())
        .use(LanguageDetector())
        .use(LanguageStorage())
        .init({
          availableLanguages,
          defaultLanguage: 'en',
          onTranslationMissing: error => {
            debug('translation missing', error);
            return error.defaultValue || 'translation missing';
          }
        });
    }
  },
  Browser,
  BrowserGuard,
];

if (environment.name !== 'production') {
  providers.push(
    { provide: 'env', useValue: environment },
  );
  providers.push({
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: DelayInterceptor,
  });
}

const imports = [
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: ((environment.name !== 'dev') || (!!localStorage.useSW)) }),
    AuthModule.forRoot(authConfig),
    SocketModule.forRoot(socketConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    HotkeyModule.forRoot(),
  ] as any[];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SupportedBrowsersComponent
  ],
  imports,
  providers,
})
export class AppModule { }
